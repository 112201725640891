import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'

import Spinner from 'react-spinner-material'
import Sidebar from '../components/sidebar'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Intro from '../components/intro'
import ActiveMenu from '../components/activemenu'
import menu from '../assets/data/menuitems'
import moment from 'moment'

const blankmenu = {
  monday: {
    daystr: 'Monday',
    menuitems: [
      {
        loaded: false,
        menuitemid: '',
        name: '',
        description: '',
        vege: false,
        gluten: false,
        dairy: false,
        imageurl: ''
      }
    ],
    deliverytimestamp: 'Wed Sep 14 2022 16:00:00 GMT+1000'
  },
  wednesday: {
    daystr: 'Wednesday',
    menuitems: [
      {
        loaded: false,
        menuitemid: '',
        name: '',
        description: '',
        vege: false,
        gluten: false,
        dairy: false,
        imageurl: ''
      }
    ],
    deliverytimestamp: 'Wed Sep 14 2022 16:00:00 GMT+1000'
  },
  friday: {
    daystr: 'Friday',
    menuitems: [      
      {
        loaded: false,
        menuitemid: '',
        name: '',
        description: '',
        vege: false,
        gluten: false,
        dairy: false,
        imageurl: ''
      }
    ],
    deliverytimestamp: 'Wed Sep 14 2022 16:00:00 GMT+1000'
  },
  ordercutofftimestamp: 'Wed Sep 14 2022 16:00:00 GMT+1000'
}
const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
class IndexPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      viewportWidth: isClient ? window.innerWidth : 0,
      loading: true,
      weekonemenu: blankmenu,
      weektwomenu: blankmenu,
      orderitems: [],
      addorderitem: false
    }    
    this.changeCookie = this.changeCookie.bind(this);
    this.additemtoorder = this.additemtoorder.bind(this);
    this.removeitemfromorder = this.removeitemfromorder.bind(this);
    this.retrieveCurrentMenu = this.retrieveCurrentMenu.bind(this);
    this.switchPage = this.switchPage.bind(this);
  }
  switchPage(pagename){
    this.setState({
        loading: true,
     });
     navigate(pagename)
  };
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  updateWindowDimensions = () => {
    // console.log(window.innerWidth)
    this.setState({ viewportWidth: window.innerWidth })
  }
  additemtoorder(neworderitem) {
    var currentOrderItems = this.state.orderitems;
    var currentItemUpdated = false;    
    if(neworderitem.qty > 0){
      for(var i = 0; i < currentOrderItems.length; i++){
        if(neworderitem.menuitem.menuitemid === currentOrderItems[i].menuitem.menuitemid){
          currentOrderItems[i].qty = neworderitem.qty;
          currentItemUpdated = true;
        }
      }
      if(!currentItemUpdated){ currentOrderItems.push(neworderitem); }
      this.setState({
        orderitems: currentOrderItems
      })
      this.changeCookie("orderitems", currentOrderItems)
    }
  }
  removeitemfromorder(itemtoremove) {    
    var currentOrderItems = this.state.orderitems;
    for(var i = 0; i < currentOrderItems.length; i++){
      if(itemtoremove.menuitem.menuitemid === currentOrderItems[i].menuitem.menuitemid){
        // console.log(itemtoremove)
        currentOrderItems.splice(i, 1);        
      }
    }
    this.setState({
      orderitems: currentOrderItems
    })
    this.changeCookie("orderitems", currentOrderItems)  
  }

  retrieveCurrentMenu(){
    var new_weekone, new_weektwo = blankmenu;
    var currentdate = moment()    
    for(var i =0; i < menu.length; i++){
      if(currentdate < moment(menu[i].ordercutofftimestamp)) {        
        new_weekone = menu[i]
        new_weekone.monday['daystr'] = moment(new_weekone.monday.deliverytimestamp).format('DD MMM YYYY')
        new_weekone.wednesday['daystr'] = moment(new_weekone.wednesday.deliverytimestamp).format('DD MMM YYYY')
        new_weekone.friday['daystr'] = moment(new_weekone.friday.deliverytimestamp).format('DD MMM YYYY')
        if((i+1) < menu.length){ 
          new_weektwo = menu[i+1]
          new_weektwo.monday['daystr'] = moment(new_weektwo.monday.deliverytimestamp).format('DD MMM YYYY')
          new_weektwo.wednesday['daystr'] = moment(new_weektwo.wednesday.deliverytimestamp).format('DD MMM YYYY')
          new_weektwo.friday['daystr'] = moment(new_weektwo.friday.deliverytimestamp).format('DD MMM YYYY')
        }
      }
      break;
    }    
    var currentState = this.state;
    this.setState({
      ...currentState,
      weekonemenu: new_weekone,
      weektwomenu: new_weektwo
    })
  }
  componentDidMount() {
    // console.log(this.props.allCookies)
    this.changeCookie('lastpage', '/')
    // if (!this.props.allCookies.firstload) {            
    //   setTimeout(() => {
    //     navigate('/loading')
    //   }, 500)
    // }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
    setTimeout(() => {
      this.retrieveCurrentMenu()
    }, 500)
    if(this.props.allCookies.orderitems) {
      this.setState({
        orderitems: this.props.allCookies.orderitems
        
      })
    }
    this.setState({ loading: false })
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }

  render() {
    const siteTitle = "Ready Made Meals"
    const keywords = ["ready made meals", "healthy", "dinner", "family", "building futures", "montessori"]
    const pageName = 'Home'
    
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }    

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          <Sidebar switchPage={this.switchPage} orderitems={this.state.orderitems} removeitemfromorder={this.removeitemfromorder}/>
          {this.state.loading ? (
            <div className="spinneroverlay">
              <div className="spinnercontainer">
                <Spinner
                  radius={100}
                  color={'#ff4c76'}
                  stroke={2}
                  visible={true}
                />
              </div>
            </div>
          ) : (
            <div />
          )}
          
          <div className="page_wrapper generalpage_wrapper">
            <Intro pagewidth={pagewidth.matches} />
            <ActiveMenu menudetails={this.state.weekonemenu} pagewidth={pagewidth.matches} addmenuitem={this.additemtoorder}/>
            
            <ActiveMenu menudetails={this.state.weektwomenu} pagewidth={pagewidth.matches} addmenuitem={this.additemtoorder}/>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(IndexPage)
