const menu = [
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Sep 26 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Sep 28 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Sep 30 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Sep 21 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Oct 03 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Oct 05 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Oct 07 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Sep 28 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Oct 10 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Oct 12 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Oct 14 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Oct 05 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Oct 17 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Oct 19 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Oct 21 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Oct 12 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Oct 24 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Oct 26 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Oct 28 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Oct 19 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Oct 31 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Nov 02 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Nov 04 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Oct 26 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Nov 07 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Nov 09 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Nov 11 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Nov 02 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Nov 14 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Nov 16 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Nov 18 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Nov 09 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Nov 21 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Nov 23 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Nov 25 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Nov 16 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Nov 28 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Nov 30 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Dec 02 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Nov 23 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Dec 05 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Dec 07 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Dec 09 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Nov 30 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Dec 12 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Dec 14 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Dec 16 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Dec 07 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Dec 19 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Dec 21 2022 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Dec 23 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Dec 14 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Dec 26 2022 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Dec 28 2022 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Dec 30 2022 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Dec 21 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Jan 02 2023 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Jan 04 2023 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Jan 06 2023 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Dec 28 2022 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Jan 09 2023 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Jan 11 2023 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Jan 13 2023 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Jan 04 2023 16:00:00 GMT+1000'
  },
  {
    monday: {
      daystr: 'Monday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'f8bc7c32-4204-43b6-a1bc-5bedc8289ebc',
          name: 'Chicken Katsu Curry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/09c221d5-e6ab-47a6-b113-e1131a28ddbb.jpg'
        },
        {
          loaded: false,
          menuitemid: 'c0d79f40-c0b7-4797-b465-434ea02333d9',
          name: 'Vegetarian Katsu with Rice',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/7cf5ab96-1e69-48cb-b67e-6a282db00766.jpg'
        }
      ],
      deliverytimestamp: 'Mon Jan 16 2023 16:00:00 GMT+1000'
    },
    wednesday: {
      daystr: 'Wednesday',
      menuitems: [
        {
          loaded: false,
          menuitemid: '5a163369-ce55-437a-8e22-83b84e9805b7',
          name: 'Lasagna',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/35f903da-7a73-46f8-a753-c3d71ba929a0.jpg'
        },
        {
          loaded: false,
          menuitemid: 'ae38feca-ae36-4755-adb8-f404890e4e16',
          name: 'Lentil Lasagna',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/22350bdc-324c-430d-a8f9-256e7e39b460.jpg'
        }
      ],
      deliverytimestamp: 'Wed Jan 18 2023 16:00:00 GMT+1000'
    },
    friday: {
      daystr: 'Friday',
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bbcc9d6c-43df-4207-900d-dc9f7a9ce30c',
          name: 'Beef Rissoles with Mash and Steamed Greens',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/daeeac53-6898-4c12-bc16-c1bd8d112890.jpg'
        }
      ],
      deliverytimestamp: 'Fri Jan 20 2023 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Jan 11 2023 16:00:00 GMT+1000'
  },
  {
    monday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '9a57a216-a981-4cb8-9289-bfa43ca1ed8c',
          name: 'Shepards Pie',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/cd62ba66-21af-49ee-a62a-a8d0da22f72d.jpg'
        },
        {
          loaded: false,
          menuitemid: 'a5eb9e29-e1bc-49b0-8ca6-d3253a3ec525',
          name: 'Lentil Shepards Pie',
          description: '',
          vege: true,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/4d0d204c-2e1c-4fe9-92f2-da6c9ab4ff05.jpg'
        }
      ],
      deliverytimestamp: 'Mon Jan 23 2023 16:00:00 GMT+1000'
    },
    wednesday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: 'bfbaac4a-69da-411a-a154-c88a74a07c06',
          name: 'Beef and Gnocchi Caserole',
          description: '',
          vege: false,
          gluten: false,
          dairy: false,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/debd7375-bc23-4d2a-8af6-b09f32d9194d.jpg'
        }
      ],
      deliverytimestamp: 'Wed Jan 25 2023 16:00:00 GMT+1000'
    },
    friday: {
      menuitems: [
        {
          loaded: false,
          menuitemid: '150a3ee0-e98a-425d-939b-d3ea88eb3c4d',
          name: 'Mongolian Lamb Stir Fry with Rice',
          description: '',
          vege: false,
          gluten: false,
          dairy: true,
          imageurl: 'https://ready-made-meals.s3.ap-southeast-2.amazonaws.com/60360038-8bc7-4da7-a431-32b978448455.jpg'
        }
      ],
      deliverytimestamp: 'Fri Jan 27 2023 16:00:00 GMT+1000'
    },
    ordercutofftimestamp: 'Wed Jan 18 2023 16:00:00 GMT+1000'
  }
]
export default menu


