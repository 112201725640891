import React from 'react'
import { Link } from 'gatsby'
import { Grid, Button, InputLabel, Select, MenuItem } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import rmm_constants from '../assets/data/constants'
import Spinner from 'react-spinner-material'
const theme = createTheme({    
    palette: {
      primary: {
        main: '#ffffff',
        darker: '#ff4c76'
      },      
    },
  });
class ActiveMenuItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            largesizeqty: 0,
            smlsizeqty: 0
        }
        this.handleqtychange = this.handleqtychange.bind(this);
        this.addmenuitem = this.addmenuitem.bind(this);
    }
    handleqtychange = (event) => {
        
        if(event.target.name === 'lrg-qty-select') {
            this.setState({largesizeqty: event.target.value})
        }
        if(event.target.name === 'sml-qty-select') {
            this.setState({smlsizeqty: event.target.value})
        }
    }
    addmenuitem = (event) => {
        if(event.target.name=='addLrgItem'){
            var menuitem = {
                menuitem: this.props.menuitem,
                size: 'large',
                qty: this.state.largesizeqty,
                deliverytimestamp: this.props.deliverytimestamp
            }
            this.props.addmenuitem(menuitem)
        } else {
            var menuitem = {
                menuitem: this.props.menuitem,
                size: 'small',
                qty: this.state.smlsizeqty,
                deliverytimestamp: this.props.deliverytimestamp
            }
            this.props.addmenuitem(menuitem)
        }
        

    }
    render() {
        var dropdownTheme = {
            height:"5vmin", 
            fontSize:"1.5vmin"
        }
        var buttonTheme = {
            color: '#ffffff', 
            backgroundColor: '#ff4c76' 
        }
        if(this.props.pagewidth) {
            dropdownTheme = {
                height:"10vmin", 
                fontSize:"3vmin"
            }
            buttonTheme = {
                color: '#ffffff', 
                backgroundColor: '#ff4c76',
                height:"10vmin", 
                fontSize:"3vmin" 
            }
        }
        if(this.props.menuitem.loaded) {
            return(          
                <Grid container spacing={0} className="menuitem_container">
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>                                
                                <img className="menuitem_img" alt="" src={this.props.menuitem.imageurl} />                                
                            </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                            <Grid item xs={10}> 
                                <h3 className='menuitem_h3'>{this.props.menuitem.name}</h3>
                            </Grid>
                            <Grid item xs={2}/>                                 
                        </Grid>
                        <ThemeProvider theme={theme}>
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} />
                                        <Grid item xs={5}>
                                            <h4 className='menuitem_h4'>Large Size  $ {rmm_constants.large_item_price.toFixed(2)}</h4>
                                        </Grid>
                                        <Grid item xs={3}>                                            
                                            <Select labelId="lrg-qty-select" id="lrg-qty-select" value={this.state.largesizeqty} label="Qty" 
                                                sx={dropdownTheme} name="lrg-qty-select" onChange={this.handleqtychange}>
                                                <MenuItem value={0}>0</MenuItem>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button className="orderbutton" name="addLrgItem" value={this.props.menuitem.menuitemid+"_lrg_"+this.state.largesizeqty} 
                                                sx={buttonTheme} variant="contained" onClick={this.addmenuitem}>Add </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={0}>
                                        <Grid item xs={1} />
                                        <Grid item xs={5}>
                                            <h4 className='menuitem_h4'>Small Size  $ {rmm_constants.small_item_price.toFixed(2)}</h4>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Select labelId="sml-qty-select" id="sml-qty-select" value={this.state.smlsizeqty} label="Qty" 
                                                sx={dropdownTheme} name="sml-qty-select" onChange={this.handleqtychange}>
                                                <MenuItem value={0}>0</MenuItem>
                                                <MenuItem value={1}>1</MenuItem>
                                                <MenuItem value={2}>2</MenuItem>
                                                <MenuItem value={3}>3</MenuItem>
                                                <MenuItem value={4}>4</MenuItem>
                                                <MenuItem value={5}>5</MenuItem>
                                            </Select>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Button className="orderbutton" name="addSmlItem" value={this.props.menuitem.menuitemid+"_sml_"+this.state.largesizeqty} 
                                                sx={buttonTheme} variant="contained" onClick={this.addmenuitem}>Add </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ThemeProvider>
                    </Grid>
                </Grid>                
            )
        }
        else {
            return(
                <Grid container spacing={0} className="menuitem_container">
                    <Grid item xs={12}>
                        <div className="menuitem_spinneroverlay">
                            <div className="menuitem_spinnercontainer">
                                <Spinner radius={15} color={'#ff4c76'} stroke={2}
                                    visible={true}/>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            )            
        }        
    }
}

export default ActiveMenuItem
