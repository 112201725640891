import React from 'react'
import { Grid } from '@mui/material'
import baselogo from '../assets/images/bfm-logo.png'

const Intro = (props) => (
  <div className="pagesection_main">
    <div className="container">
      <hr style={{ border: '0.5px solid #ff4c76' }} />
      <div className="row">
        <div className="col-12">
          <header>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <img className='mainlogo' src={baselogo} />
              </Grid>              
              <Grid item xs={12}>
                <h1 style={{ color: '#757575' }}>Ready Made Meals</h1>
              </Grid>              
            </Grid>
            
            
            <p className="introhighlight">
              Building Futures Ready Made Meals are Single serving size, small or large option available, made to order 3 times a week for your convenience.
            </p>
          </header>
        </div>
        <div className="col-4 col-12-medium">
          <p style={{ color: '#757575' }}>            
            These Meals follow the same Food Mission Goals as the lunches and afternoon tea that the children eat each day at Building Futures Montessori. The Aim is to make an initiative to help those families who are short on time but still wish to provide healthy and nutrition filled food made with fresh ingredients.
            Please note that this is currently a trial phase, and feedback will help fine tune the initiative. A survey will be sent out via email after the collection of your order.
          </p>
        </div>
        <div className="col-4 col-12-medium">
          <p style={{ color: '#757575' }}>
            Orders are purchased through the website by Wednesday for the following week. Meals are then conveniently available for pickup at 4pm on the designated day.
          </p>
        </div>        
      </div>
      <hr style={{ border: '0.5px solid #ff4c76' }} />
    </div>
  </div>
)
export default Intro
