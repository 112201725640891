import React from 'react'
import { Grid } from '@mui/material'
import ActiveMenuDay from './activemenuday'
import moment from 'moment'

class ActiveMenu extends React.Component {
  render() {
    var menu = this.props.menudetails;    
    var menuitems = []
    if(this.props.pagewidth) {
      menuitems.push(
        <Grid key="monday" item xs={12}>
          <ActiveMenuDay menuitems={menu.monday} addmenuitem={this.props.addmenuitem} pagewidth={this.props.pagewidth} />
        </Grid>
      )
      menuitems.push(
        <Grid key="wednesday" item xs={12}>
            <ActiveMenuDay menuitems={menu.wednesday} addmenuitem={this.props.addmenuitem} pagewidth={this.props.pagewidth} />
          </Grid>
        )
      menuitems.push(
          <Grid key="friday" item xs={12}>
            <ActiveMenuDay menuitems={menu.friday} addmenuitem={this.props.addmenuitem} pagewidth={this.props.pagewidth} />
          </Grid>
        )

    } else {
      menuitems.push(
        <Grid key="monday" item xs={4}>
          <ActiveMenuDay menuitems={menu.monday} addmenuitem={this.props.addmenuitem} pagewidth={this.props.pagewidth} />
        </Grid>
      )
      menuitems.push(
        <Grid key="wednesday" item xs={4}>
            <ActiveMenuDay menuitems={menu.wednesday} addmenuitem={this.props.addmenuitem} pagewidth={this.props.pagewidth} />
          </Grid>
        )
      menuitems.push(
          <Grid key="friday" item xs={4}>
            <ActiveMenuDay menuitems={menu.friday} addmenuitem={this.props.addmenuitem} pagewidth={this.props.pagewidth} />
          </Grid>
        )
    }
    const menuStandard = (
      <Grid container spacing={0}>
        <Grid item xs={12} >
          <Grid container spacing={0}>
            <Grid item xs={12} >
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                    <Grid container spacing={0}>
                        <Grid item xs={8} />
                        <Grid item xs={4}>
                            <h2 style={{textAlign:"right" }}>Order By: {moment(menu.ordercutofftimestamp).format("dddd - DD MMM YYYY HH:mm")}</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {menuitems}                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )    
    const menuStandardMob = (
      <Grid container spacing={0}>
        <Grid item xs={12} >
          <Grid container spacing={0}>
            <Grid item xs={12} >
              <Grid className="pagesection_text" container spacing={0}>
                <Grid item xs={12}>
                    <Grid container spacing={0}>                        
                        <Grid item xs={12}>
                            <h2 style={{textAlign:"center" }}>Order By: {moment(menu.ordercutofftimestamp).format("dddd - DD MMM YYYY HH:mm")}</h2>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {menuitems}                    
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
    return (
      <div className="pagesection_main">
        {/* <Fade duration={2000}> */}
        <div className="container">
          {this.props.pagewidth ? menuStandardMob : menuStandard}
          <hr style={{ border: '0.5px solid #ff4c76' }} />
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default ActiveMenu