import React from 'react'
import { Grid } from '@mui/material'
import ActiveMenuItem from './activemenuitem'
import moment from 'moment'

class ActiveMenuDay extends React.Component {
  render() {   
    var menu = this.props.menuitems;    
    var menuitems = []    
    for(var i = 0; i < menu.menuitems.length; i++) {
      menu.menuitems[i].loaded = true;      
      menuitems.push(
        <ActiveMenuItem key={i} menuitem={menu.menuitems[i]} addmenuitem={this.props.addmenuitem} 
          deliverytimestamp={menu.deliverytimestamp} pagewidth={this.props.pagewidth} />
      )
    }
    return (              
      <div className="activemenuday_container">        
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h4>{moment(this.props.menuitems.deliverytimestamp).format("dddd - DD MMM YYYY")}</h4>
              </Grid>
              <Grid item xs={12}>
                {menuitems}                
              </Grid>
            </Grid>

          </Grid>                    
      </div>              
    )
  }
}

export default ActiveMenuDay